<template>
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>用户列表</template>

      <template v-slot:menu-block1>
        <router-link :to="{name: 'UserEdit'}">
          <button class="btn btn-blue">
            <span class="mr-2">新增用户</span>
            <img class="icon-16 d-inline-block" src="/static/images/icon/add_12.svg">
          </button>
        </router-link>
      </template>
    </TopMenu>

    <SubMenu
      :labels="check"
      :options="submenu"
      @call="_call"
      @change="_search"
      v-model="submenu.search.value"
    />

    <div class="content bg-25 h-100">
      <div class="d-flex">
        <label><div class="flex-list-header hr"><input name="check" type="checkbox" :checked="checkAll" @click="funCheckAll" /></div></label>
        <div class="flex-list hr w-100">
          <div style="width:50%;padding-left:12px;">登录名</div>
          <div style="width:50%;">真实名称</div>
        </div>
      </div>

      <div
      :class="active==index?'d-flex hover active':'d-flex hover'"
      v-for="(data, index) in datas.user"
      :key="data.id"
      >
          <label><div class="flex-list-header hr"><input name="check" type="checkbox" :value="data" v-model="check"/></div></label>
          <div class="flex-list hr w-100 btn-default" @click="showList(data, index)">
            <div style="width:50%;padding-left:12px;">{{ data.username }}</div>
            <div style="width:50%;">{{ data.nickname }}</div>
          </div>
      </div>
    </div>

    <div class="pagination">
      <v-pagination
        v-model="currentPage"
        :pages="totalPage"
        active-color="#004bff"
        @update:modelValue="getDatas($event, submenu.search.value)"
      />
    </div>
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import SubMenu from '@/components/SubMenu.vue'

export default {
  name: 'UserList',

  data() {
    return {
      loading: true,
      datas: [],
      active: null,
      check: [],
      checkAll: false,
      currentPage: 1,
      totalPage: 1,
      submenu: {
        edit: {
          disabled: 'singleDisabled',
          fetch: 'UserEdit',
          permissions: '402'
        },
        del: {
          fetch: '/api/user/delete',
          permissions: '403'
        },
        export: {
          fetch: '/api/user/export',
          permissions: '404'
        },
        search: {
          value: '',
        }
      }
    }
  },

  mounted() {
    console.log(this.$options.name+' 挂载')

    this.getDatas()
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    SubMenu
  },

  watch: {
    check: function() {
      if (this.datas.user && this.datas.user.length > 0) {
        //全选
        if (this.check.length != this.datas.user.length) {
          this.checkAll = false
        } else {
          this.checkAll = true
        }
      }
    },

    datas: function() {
      this.checkAll = false
      this.check = []
      this.active = null
    },
  },

  methods: {
    getDatas(page, search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let p = page ? page : 1
        _this.$axios.get('/api/user', {
          headers: {
            token: JSON.parse(sessionStorage.Authorization).token
          },
          params: {
            page: p,
            take: 20,
            search: search
          }
        })
        .then(res => {
          resolve(res);

          if (res.status == 200) {
            if (res.data) {
              this.datas = res.data
              this.currentPage = res.data.current_page
              this.totalPage = res.data.total_page
            }
            this.loading = false
          }
          console.log(res)
        })
        .catch(error => {
          reject(error)
          console.log('error:', error)
        })
      });
    },

    showList(data, index) {
      this.active = index
    },

    funCheckAll() {
      let _this = this
      this.check = []
      if (!this.checkAll && this.datas.user) {
        this.datas.user.forEach(function(item) {
          _this.check.push(item)
        })
      }
      this.checkAll = !this.checkAll
    },

    //子组件方法
    _search(e) {
      this.submenu.search.value = e.target.value;
      this.getDatas(null, e.target.value)
    },

    _call(data) {
      if (data.res.success) {
        let _this = this
        //let oldDatas = this.datas.order

        if (data.function == 'del') {
          this.getDatas(this.currentPage).then(res => {
            if (res.status == 200) {
              _this.datas = res.data
              if (res.data.current_page > res.data.total_page) {
                this.getDatas(res.data.total_page)
              }
            }
          })
        }

        if (data.function == 'copy') {
          this.getDatas().then(res => {
            if (res.status == 200) {
              res.data.user.forEach(function(item) {
                let newData = false;
                data.res.data.forEach(function(i) {
                  if (item.id == i) {
                    newData = true;
                    return false;
                  }
                })
                item.new_data = newData
              })
              _this.datas = res.data
            }
          })
        }
      }
    }

  },
  
}
</script>

<style scoped>
.active {
  background-color: #004bff !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6:first-child {
  padding-left: 0;
}
.col-6:last-child {
  padding-right: 0;
}

</style>
